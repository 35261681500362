/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  overflow-x: hidden;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 220px;
  transition: margin-left 0.3s ease-in-out;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 320ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 320ms ease-in;
}

@media (max-width: 992px) {
  .content-wrapper {
    margin-left: 0;
    padding-top: 60px; /* Add padding to avoid content overlapping with toggle button */
    justify-content: flex-start;
  }

  .sidebar-toggle {
    top: 20px; /* Adjust position if needed */
  }
}
