@font-face {
    font-family: 'SF Pro';
    src: url('SFPRODISPLAYREGULAR.OTF') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('SFPRODISPLAYMEDIUM.OTF') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

