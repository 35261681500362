
body {
    font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  
  .header-text {
    text-align: center;
    padding: 20px 0;
  }
  
  .header-text sup {
    font-size: 1rem;
    position: relative;
    top: -0.9em;
    background: linear-gradient(100deg, #b6b6b6 .11%, #1D1D1F 1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: left;
    font-weight: 500;
  }
  
  .header-text p {
    font-size: 1.5rem;
    margin: 0;
    background: linear-gradient(120deg, #9e9e9e 1%, #1D1D1F 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: left;
    font-weight: 500;
  }

  .header-text h1 {
    font-size: 3rem;
    background: linear-gradient(110deg, #9e9e9e 1%, #1D1D1F 85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin: 10px 0;
    display: block;
    width: 85%;
    text-align: left;
    margin-top: 12px;
    line-height: 1.1;
  }
  
  .separator {
    width: 80%;
    margin: 20px auto;
    border: 1px solid #ddd;
  }
  
  @media (max-width: 768px) {
    
    .header-text {
      padding: 10px 0;
    }
  
    .header-text sup {
      font-size: 0.8rem;
      top: -0.7em;
    }
  
    .header-text p {
      font-size: 1.2rem;
    }
  
    .header-text h1 {
      font-size: 2.5rem;
      width: 90%;
    }
  
    .separator {
      width: 90%;
      margin: 10px auto;
    }
  }
  
  @media (max-width: 576px) {
    .header-text {
      margin-top: 0px;
      padding-top: 10px;
    }
  
    .header-text sup {
      font-size: 0.6rem;
      top: -0.6em;
    }
  
    .header-text p {
      font-size: 0.8rem;
    }
  
    .header-text h1 {
      font-size: 2rem;
      width: 95%;
      line-height: 1.1;
    }
  
    .separator {
      width: 95%;
      margin: 5px auto;
    }
  }
  