/* src/components/About.css */

body {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #1D1D1F;
  padding-left: 20px;
}

.about-container {
  padding: 20px;
  padding-left: 0;
  max-width: 800px;
  margin: 0 auto;
}

.section {
  margin-bottom: 40px;
}

.header-textt h1 {
  display: block;
  font-size: 10rem;
  font-weight: bold;
  background: linear-gradient(95deg, #f3f2f2 4%, #1D1D1F 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  line-height: 1;
}

.journey-text {
  font-size: 1.5rem;
  color: #333;
  margin-top: 10px;
}

.separator {
  width: 80%;
  margin: 20px auto;
  border: 1px solid #ddd;
}

.timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #1D1D1F;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

/* Timeline item styles */
.timeline-item {
  padding: 10px 0;
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.timeline-item:nth-child(odd) {
  left: 0;
  padding-right: 40px;
  text-align: right;
}

.timeline-item:nth-child(even) {
  left: 50%;
  padding-left: 40px;
}

/* Timeline dot styles */
.timeline-item::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #1D1D1F;
  border: 2px solid #1D1D1F;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-item:nth-child(odd)::after {
  right: -9px;
}

.timeline-item:nth-child(even)::after {
  left: -9px;
}

/* Content styles */
.content {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}

.date {
  position: absolute;
  top: 15px;
  padding: 8px;
  border-radius: 8px;
  background-color: #f3f2f2;
  font-size: 14px;
  font-weight: 400;
  z-index: 2;
}

.timeline-item:nth-child(odd) .date {
  right: -140px;
}

.timeline-item:nth-child(even) .date {
  left: -140px;
}

.content h3 {
  margin: 0 0 10px 0;
  color: #1D1D1F;
  font-size: 1.2rem;
}

.content p {
  margin: 0 0 5px 0;
  color: #555;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  body {
    padding-left: 0px;
  }

  .header-textt h1 {
    font-size: 7rem;
  }

  .timeline::after {
    left: 31px;
  }

  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    left: 0;
    text-align: left;
  }

  .timeline-item::after {
    left: 23px;
  }

  .timeline-item:nth-child(odd)::after,
  .timeline-item:nth-child(even)::after {
    left: 23px;
  }

  .date {
    position: static;
    display: inline-block;
    margin-bottom: 10px;
  }

  .timeline-item:nth-child(odd) .date,
  .timeline-item:nth-child(even) .date {
    left: auto;
    right: auto;
  }

  .content {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 576px) {
  .header-textt h1 {
    font-size: 6.5rem;
  }

  .timeline::after {
    left: 21px;
  }

  .timeline-item {
    padding-left: 50px;
  }

  .timeline-item::after {
    left: 13px;
  }

  .timeline-item:nth-child(odd)::after,
  .timeline-item:nth-child(even)::after {
    left: 13px;
  }

  .date {
    font-size: 12px;
  }

  .content h3 {
    font-size: 1rem;
  }

  .content p {
    font-size: 0.85rem;
  }
}