@import url('https://fonts.cdnfonts.com/css/sequel');
body {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.header-nav {
  width: 240px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-right: 0.9px solid rgba(0,0,0,.3);
  padding: 10px 30px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.brand-text {
  padding: 8px;
  border-radius: 8px;
  background-color: #f3f2f2;
  font-size: 25px;
  font-weight: 400;
  padding-left: 8px;
}

.primary-nav {
  display:list-item;
}

.primary-text {
  color: #1D1D1F;
  padding: 8px;
  font-size: large;
  font-weight: 550;
  cursor: pointer;
  transition: color 0.3s ease;
  width: 100%;
  display: block; /* Ensure each link is on a separate line */
}

.primary-text.active,
.primary-text:hover {
  color: #A0A5AB;
}

.secondary-text {
  display: block;
  padding: 6px 8px;
  font-size: medium;
  font-weight: 400;
  color: #1D1D1F;
}

.secondary-text:hover {
  color: #A0A5AB;
}

.header-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
}

.header-section:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  right: -30px;
  height: 0.5px;
  background-color: rgba(0,0,0,.3);
}

.secondary-links {
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.secondary-links.hidden {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.secondary-links:not(.hidden) {
  max-height: 200px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 800px) {
  body{
    padding-left: 0px;
  }
  
  .header-nav {
    width: 100%;
    height: 80px;
    border-bottom: 0.9px solid rgba(0,0,0,.3);
    flex-direction: row;
  }

  .header-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
  }

  .primary-text {
    display: inline-block;
    width: auto;
    font-size: 14px;
  }

  .projects-section,
  .contact-section {
    display: none;
  }

  .nav-item {
    margin-left: 10px;
  }

  .header-brand {
    padding: 0;
  }
  .brand-text {
    padding: 5px;
    border-radius: 8px;
    background-color: #f3f2f2;
    font-size: 16px;
    font-weight: 400;
    padding-left: 8px;
  }

  .navbar-nav {
    margin-left: 20px;
  }

  .header-section:not(:last-child)::after {
    display: none;
  }
}
