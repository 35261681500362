/* src/components/Footer.css */

body {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

footer {
  padding: 10px;
  background-color: #ffffff;
  text-align: center;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}
