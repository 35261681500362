body {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.tile-container {
  margin-bottom: 30px;
  width: 100%;
  max-width: 450px;
}

.tile {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tile:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.tile-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.tile-media.animating {
  animation: fadeZoom 1.5s ease-in-out;
}

@keyframes fadeZoom {
  0% {
    opacity: 0.7;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.tile-content {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tile-text {
  max-width: 70%;
}

.tile-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1D1D1F;
}

.tile-subtitle {
  margin: 5px 0 0;
  font-size: 14px;
  color: #86868B;
}

.tile-date {
  font-size: 12px;
  color: #86868B;
  text-align: right;
}

@media (max-width: 768px) {
  .tile-container {
    margin-bottom: 20px;
  }
}