body {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.chronos-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.chronos-container .image-tile {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.chronos-container .github-link, .chronos-container .chronos-link {
  color: #1D1D1F;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.chronos-container .image-tile:hover {
  transform: scale(1.02);
}

.chronos-container .tile-image {
  width: 100%;
  height: auto;
  transition: opacity 1s ease-in-out;
}

.chronos-container .content-section {
  margin-top: 20px;
}

.chronos-container h1 {
  font-size: 1.5rem;
  color: #1D1D1F;
}

.chronos-container h2 {
  font-size: 1rem;
  color: #A0A5AB;
  margin-bottom: 10px;
}

.chronos-container p {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
}

.chronos-container .separator {
  width: 80%;
  margin: 20px auto;
  border: 1px solid #ddd;
}

.chronos-container .stack {
  text-align: left;
}

.chronos-container .stack p {
  margin-top: 0;
}

.chronos-container > :last-child {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.chronos-container .github-link:hover, .chronos-container .chronos-link:hover {
  color: #A0A5AB;
}

@media (max-width: 992px) {
  .chronos-container .tile-image {
    max-width: 100%;
  }
  .chronos-container h1 {
    font-size: 2.5rem;
  }
  .chronos-container h2 {
    font-size: 1.8rem;
  }
  .chronos-container p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .chronos-container {
    padding: 10px;
  }
  .chronos-container .tile-image {
    max-width: 100%;
  }
  .chronos-container h1 {
    font-size: 1.5rem;
  }
  .chronos-container h2 {
    font-size: 1rem;
  }
  .chronos-container p {
    font-size: 0.8rem;
  }
  .chronos-container .separator {
    width: 90%;
  }
}
